import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "comcare-attributes-and-sample-transaction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#comcare-attributes-and-sample-transaction",
        "aria-label": "comcare attributes and sample transaction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare attributes and sample transaction`}</h1>
    <p><strong parentName="p">{`Creating a transaction`}</strong></p>
    <p><strong parentName="p">{`Use function`}</strong>{`:`}<Highlight mdxType="Highlight">{` medipassTransactionSDK.renderCreateTransaction() `}</Highlight></p>
    <p>{`To process a Comcare insurance claim, the following fields apply. Any required field not passed to the SDK will be prompted at the TyroHealth submission page.`}</p>
    <div style={{
      display: 'table',
      width: '100%'
    }}>
  <div style={{
        width: '60%',
        float: 'left',
        padding: '10px'
      }}>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Field`}</strong></th>
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Notes`}</strong></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`funder`}</inlineCode><br /><ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Set to (`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`comcare`}</Highlight>{`)for Comcare claims`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`providerNumber`}</inlineCode><br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Medicare provider number for individual providers `}<br /><br />{` `}<strong parentName="td">{`General Practice Example`}</strong>{`:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2429591L`}</Highlight>{` `}<br /><br />{`Or  `}<br />{`Tyro Health generated provider number for location level claiming `}<br />{` `}<strong parentName="td">{`Pharmacy Example`}</strong>{`:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`T243536Y`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode>{`  `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system.  Used for reconciliation purposed. `}<br />{` Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`156`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`-----`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td"><inlineCode parentName="strong">{`patient`}</inlineCode></strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Object`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode>{` `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Patient/employee’s first name as registered with Comcare. `}<br /><br />{` Middle name is not supported.If the patient has only one name, set that in the lastName field and set firstName to “onlyname”. `}<br />{` `}<br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Tara`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode>{` `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Patient/employee’s last name as registered with Comcare. `}<br /><br />{` Example: `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Curtin`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`patient.dob`}</inlineCode><br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Patient/employee’s Date of Birth in ‘YYYY-MM-DD’ format. `}<br /><br />{` Example: `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1991-06-13`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`patient.claimNumber`}</inlineCode>{` `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Patient/employee’s claim  number/case number `}<br />{` `}<br />{`The claim number is presented first followed by a forward slash and case number. If the case number is not known, use /01. `}<br />{` `}<br />{` Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`987654/01`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`OR`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` An optional but recommended patient unique reference as set by you, such as a UUID.`}<br />{` `}<br />{` This value is used to uniquely identify a patient in Medipass. `}<br />{` `}<br />{`  Omitting refId will cause duplicate patient records to be created and could make patient searches more difficult for providers who also use the Medipass portal to check on status of a claim and remittance. `}<br /><br />{` When supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details sent for an existing patient record will update those values. `}<br />{` `}<br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`5AD5E2AF-69D2-43D3-9321-428495205E5E`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td"><inlineCode parentName="strong">{`funderData`}</inlineCode></strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Object`}</strong><br /></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td"><inlineCode parentName="strong">{`funderData.comcare`}</inlineCode></strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Object`}</strong><br /></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`funderData.comcare.serviceNote`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`string`}</strong><br />{` Invoice level notes for the transaction. Usually used  to explain why, for example, two consults were done on the same day. Think of this as a note you would add to an invoice to help get the invoice paid. `}<br /><br />{` `}<strong parentName="td">{`Up to 512 characters`}</strong>{` `}<br /><br />{` Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Current medications:Hydrochlorothiazide`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Array`}</strong>{`{Object}`}<br />{` Array of fund claimable items.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].itemCode`}</inlineCode>{` `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Comcare supported paycode which includes MBS/PBS/Comcare/Private  derived item codes `}<br />{` Item code must be valid for Comcare, the provider registered profession and service date. `}<br /><br />{` `}<strong parentName="td">{`Up to 8 characters`}</strong><br /><br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`10102Q`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].serviceDate`}</inlineCode><br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Date of the service `}<br />{` format:'YYYY-MM-DD' `}<br /><br />{` Example: `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2021-11-02`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].price`}</inlineCode>{`   `}<br />{` `}<ColouredText mdxType="ColouredText">{`Required`}</ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` Item per unit pricing. This value will be deemed tax-inclusive if the isTaxable field  is set to ‘true’. If isExtendedPrice is set to ‘true’ this will be the aggregate amount for all quantity set.  `}<br /><br />{` Price each unit in $XX.XX format.`}<br /><br />{` For example if the price without tax for an item per unit is $9.00, this field would have $9.00 and isTaxable = false if tax is not to be charged. If tax is to be charged, this field would show $9.90 and isTaxable = true  `}<br /><br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`$15.95`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].isTaxable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Boolean`}</strong><br />{`Default is false. When true, GST of 10% will be applied to the gross item amount`}<br />{` `}<br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`True`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].quantity`}</inlineCode>{` `}<br /></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Number`}</strong><br />{`Quantity of the item or service relevant to the item’s unit of Measure:`}<br />{` `}<br />{` Item based (consultations etc, quantity value must be 1) `}<br />{`Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1`}</Highlight>{` `}<br />{` `}<br />{` kilometers (Travel per KMs) Example: `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`30`}</Highlight>{` `}<br /><br />{`  Hours (Time based services) Example: `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1.5`}</Highlight>{` `}<br /><br />{` Quantity Dispensed(Medications) Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2`}</Highlight>{` `}<br />{` `}<br />{` Can include up to 2 decimal places (used of medications) Example:  `}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2.5 packets`}</Highlight></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`claimableItems[].customDescription`}</inlineCode>{` `}<br />{` `}<ColouredText textColour="orange" mdxType="ColouredText"><strong parentName="td">{`Conditional`}</strong></ColouredText></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`String`}</strong><br />{` If the item code being used has the ability to accept a custom description, you can enter it here. This is typically used on generic item codes that need further elaboration `}<br /><br />{` Up to 128 characters`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`funderData.comcare.serviceNote`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`string`}</strong><br />{` Invoice level notes for the transaction. Usually used  to explain why, for example, two consults were done on the same day. Think of this as a note you would add to an invoice to help get the invoice paid. `}<br /><br />{` `}<strong parentName="td">{`Up to 512 characters`}</strong>{` `}<br /><br />{` Example:`}<Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Current medications:Hydrochlorothiazide`}</Highlight></td>
            </tr>
          </tbody>
        </table>
  </div>
  <div style={{
        width: '40%',
        float: 'left',
        padding: '20px',
        'background-color': "#16556e"
      }}>
        <pre><code parentName="pre" {...{}}>{`Sample Transaction
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`medipassTransactionSDK.renderCreateTransaction({
   funder: string,
   providerNumber: string,
   invoiceReference: string,
 
   patient: {
     firstName: string,
     lastName: string,
     dob: string,
     claimNumber: string,
   },
   funderData: {
       comcare: {
         serviceNotes: string
       },
   },
 
   claimableItems: [
     {
       itemCode: string,
       serviceDate: string,
       price:string,
       description: string

     }
   ],
   webhooks: [
   {
     url: string,
     event: 'healthInvoiceCancelled' | 'healthFundApprovedInvoice' |    'healthFundRejectedInvoice',
     method: 'POST',
     headers: { [key: string] : 'your string' }
   }],
}, {
 onSuccess: function (transaction) { /* ... */ },
 onError: function (error) { /* ... */ },
 onCancel: function () { /* ... */ }
})

`}</code></pre>
  </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "claim-response-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claim-response-attributes",
        "aria-label": "claim response attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim response attributes`}</h4>
    <p>{`When a transaction is processed, the SDK will pass through the transaction details to the first parameter of the onSuccess callback.`}</p>
    <p>{`The response payload contains a number of attributes with a complete description of the transaction model in our online API documentation.`}</p>
    <p><strong parentName="p">{`Key response attributes include`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`_`}{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`5ce4d63cb0a38c005582b838`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass unique transaction reference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`patient {}`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`Object`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient names as validated with Comcare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`patient.firstName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Willis`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first / given name as registered with Comcare.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`patient.lastName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Curtin`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last / family name as registered with Comcare.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`patient.dobString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1991-13-06 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of birth in YYYY-MM-DD format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`practice`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`Object`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`practice.id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`61f7297f272329006343997e`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal practice ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`practice.fullName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of practice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`claims`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`Object`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Approved`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of overall claim/quote`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.statusDescription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Payment Approved `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of the status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.gatewayCode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item gateway response code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.amountExpectedBenefitString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`8000 / $80.00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total claim benefit amount in cents or (string) in currency notation Comcare is expected to pay.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claimItems.amountActualBenefitString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`$50.00 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount Comcare has approved to pay in currency format. E.g. $119.95.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.amountGap/ claims.amountGapString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 6000 / $60.00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total claim gap (employee payable) amount in cents or (string) in currency notation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`claims.claimItems`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`Array`}</strong>{` `}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{` `}<strong parentName="em">{`For each claimed item, a separate line level response as below.`}</strong>{` `}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.claimItems.status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Approved`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of the claim/quote item level status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.claimItems.gatewayCode?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim gateway response code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.claimItems.amountActualBenefit / claims.claimItems.amountActualBenefitString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`4000 / $40.00 `}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item benefit amount in cents or (string) in currency notation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`claims.claimItems.amountGap/ claims.claimItems.amountGapString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`2500 / $25.00`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item gap (member payable) amount in cents or (string) in currency notation.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      